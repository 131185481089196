// // // ********** Screen Code 1 **********
// // import React, { useState } from "react";

// // const ScreenTest = () => {
// //   const [testStarted, setTestStarted] = useState(false);
// //   const [testResult, setTestResult] = useState(null);

// //   const startTest = () => {
// //     setTestStarted(true);
// //     setTestResult(null);
// //   };

// //   const handleTouch = (e) => {
// //     const touchX = e.touches[0].clientX;
// //     const touchY = e.touches[0].clientY;

// //     // Example: Checking if the touch is in a valid screen area (simulate screen grid)
// //     if (touchX < 100 || touchY < 100) {
// //       setTestResult(false); // Fail the test if the touch is outside expected area
// //     } else {
// //       setTestResult(true); // Otherwise, pass the test
// //     }
// //   };

// //   return (
// //     <div>
// //       {!testStarted && <button onClick={startTest}>Start Screen Test</button>}

// //       {testStarted && !testResult && (
// //         <div
// //           onTouchStart={handleTouch}
// //           style={{ padding: "20px", border: "2px dashed #000" }}
// //         >
// //           <p>
// //             Touch the screen to test. Ensure your screen responds to touch
// //             correctly.
// //           </p>
// //         </div>
// //       )}

// //       {testResult === false && <p>Test failed. Screen might be damaged.</p>}
// //       {testResult === true && <p>Test passed. Screen is working fine.</p>}
// //     </div>
// //   );
// // };

// // export default ScreenTest;

// // // ••••••••••••••• Screen Code 2 •••••••••••••••
// // import React, { useState, useRef } from "react";

// // const ScreenTest = () => {
// //   const [testStarted, setTestStarted] = useState(false);
// //   const [testResult, setTestResult] = useState(null);
// //   const canvasRef = useRef(null);
// //   const ctxRef = useRef(null);

// //   const startTest = () => {
// //     setTestStarted(true);
// //     setTestResult(null);
// //   };

// //   // Initialize canvas context for drawing
// //   const initializeCanvas = () => {
// //     const canvas = canvasRef.current;
// //     const ctx = canvas.getContext("2d");
// //     ctxRef.current = ctx;
// //     ctxRef.current.strokeStyle = "#00F"; // Color of the drawing
// //     ctxRef.current.lineWidth = 10; // Width of the stroke
// //   };

// //   // Handle touch start event
// //   const handleTouchStart = (e) => {
// //     e.preventDefault();
// //     const touchX = e.touches[0].clientX;
// //     const touchY = e.touches[0].clientY;
// //     ctxRef.current.beginPath();
// //     ctxRef.current.moveTo(touchX, touchY);
// //   };

// //   // Handle touch move event for drawing
// //   const handleTouchMove = (e) => {
// //     e.preventDefault();
// //     const touchX = e.touches[0].clientX;
// //     const touchY = e.touches[0].clientY;
// //     ctxRef.current.lineTo(touchX, touchY);
// //     ctxRef.current.stroke();
// //   };

// //   // Handle touch end event
// //   const handleTouchEnd = () => {
// //     ctxRef.current.closePath();
// //   };

// //   return (
// //     <div>
// //       {!testStarted && <button onClick={startTest}>Start Screen Test 2</button>}

// //       {testStarted && !testResult && (
// //         <div
// //           onTouchStart={handleTouchStart}
// //           onTouchMove={handleTouchMove}
// //           onTouchEnd={handleTouchEnd}
// //           style={{ width: "100vw", height: "100vh", overflow: "hidden" }}
// //         >
// //           <canvas
// //             ref={canvasRef}
// //             width={window.innerWidth}
// //             height={window.innerHeight}
// //             style={{
// //               position: "absolute",
// //               top: 0,
// //               left: 0,
// //               pointerEvents: "none", // To prevent interaction with the canvas
// //             }}
// //             onLoad={initializeCanvas}
// //           ></canvas>
// //           <p
// //             style={{
// //               position: "absolute",
// //               top: "20px",
// //               left: "20px",
// //               color: "white",
// //             }}
// //           >
// //             Draw on the screen with your finger to test the touchscreen.
// //           </p>
// //         </div>
// //       )}

// //       {testResult === false && <p>Test failed. Screen might be damaged.</p>}
// //       {testResult === true && <p>Test passed. Screen is working fine.</p>}
// //     </div>
// //   );
// // };

// // export default ScreenTest;

// // // ••••••••••••••• Screen Code 3 •••••••••••••••
// // import React, { useState, useRef, useEffect } from "react";

// // const ScreenTest = () => {
// //   const [testStarted, setTestStarted] = useState(false);
// //   const [testResult, setTestResult] = useState(null);
// //   const canvasRef = useRef(null);
// //   const ctxRef = useRef(null); // Ref to hold the canvas context
// //   const [isCanvasReady, setIsCanvasReady] = useState(false); // To track if canvas is ready

// //   const startTest = () => {
// //     setTestStarted(true);
// //     setTestResult(null);
// //   };

// //   // Initialize canvas context once the canvas is available
// //   useEffect(() => {
// //     if (canvasRef.current) {
// //       const canvas = canvasRef.current;
// //       const ctx = canvas.getContext("2d");
// //       ctxRef.current = ctx;
// //       ctxRef.current.strokeStyle = "#00F"; // Color of the drawing
// //       ctxRef.current.lineWidth = 10; // Width of the stroke
// //       setIsCanvasReady(true); // Set canvas ready flag
// //     }
// //   }, []);

// //   // Handle touch start event
// //   const handleTouchStart = (e) => {
// //     e.preventDefault();
// //     if (!isCanvasReady || !ctxRef.current) return; // Check if canvas is ready
// //     const touchX = e.touches[0].clientX;
// //     const touchY = e.touches[0].clientY;
// //     ctxRef.current.beginPath();
// //     ctxRef.current.moveTo(touchX, touchY);
// //   };

// //   // Handle touch move event for drawing
// //   const handleTouchMove = (e) => {
// //     e.preventDefault();
// //     if (!isCanvasReady || !ctxRef.current) return; // Check if canvas is ready
// //     const touchX = e.touches[0].clientX;
// //     const touchY = e.touches[0].clientY;
// //     ctxRef.current.lineTo(touchX, touchY);
// //     ctxRef.current.stroke();
// //   };

// //   // Handle touch end event
// //   const handleTouchEnd = () => {
// //     if (!isCanvasReady || !ctxRef.current) return; // Check if canvas is ready
// //     ctxRef.current.closePath();
// //   };

// //   return (
// //     <div>
// //       {!testStarted && <button onClick={startTest}>Start Screen Test</button>}

// //       {testStarted && !testResult && (
// //         <div
// //           onTouchStart={handleTouchStart}
// //           onTouchMove={handleTouchMove}
// //           onTouchEnd={handleTouchEnd}
// //           style={{ width: "100vw", height: "100vh", overflow: "hidden" }}
// //         >
// //           <canvas
// //             ref={canvasRef}
// //             width={window.innerWidth}
// //             height={window.innerHeight}
// //             style={{
// //               position: "absolute",
// //               top: 0,
// //               left: 0,
// //               pointerEvents: "none", // To prevent interaction with the canvas
// //             }}
// //           ></canvas>
// //           <p
// //             style={{
// //               position: "absolute",
// //               top: "20px",
// //               left: "20px",
// //               color: "white",
// //             }}
// //           >
// //             Draw on the screen with your finger to test the touchscreen.
// //           </p>
// //         </div>
// //       )}

// //       {testResult === false && <p>Test failed. Screen might be damaged.</p>}
// //       {testResult === true && <p>Test passed. Screen is working fine.</p>}
// //     </div>
// //   );
// // };

// // export default ScreenTest;

// // ••••••••••••••• Colouring Code Once Screen Code 4 Working •••••••••••••••
// // import React, { useState, useRef, useEffect } from "react";

// // const ScreenTest = () => {
// //   const [testStarted, setTestStarted] = useState(false);
// //   const [testResult, setTestResult] = useState(null);
// //   const canvasRef = useRef(null);
// //   const ctxRef = useRef(null); // Ref to hold the canvas context
// //   const [isCanvasReady, setIsCanvasReady] = useState(false); // To track if canvas is ready

// //   const startTest = () => {
// //     setTestStarted(true);
// //     setTestResult(null);
// //   };

// //   // Initialize canvas context once the canvas is available
// //   useEffect(() => {
// //     if (canvasRef.current) {
// //       const canvas = canvasRef.current;
// //       const ctx = canvas.getContext("2d");
// //       ctxRef.current = ctx;
// //       ctxRef.current.strokeStyle = "#00F"; // Color of the drawing (blue)
// //       ctxRef.current.lineWidth = 10; // Width of the stroke
// //       ctxRef.current.lineCap = "round"; // Smooth edges for the drawing
// //       setIsCanvasReady(true); // Set canvas ready flag
// //     }
// //   }, []);

// //   // Handle touch start event
// //   const handleTouchStart = (e) => {
// //     e.preventDefault();
// //     if (!isCanvasReady || !ctxRef.current) return; // Check if canvas is ready
// //     const touchX = e.touches[0].clientX;
// //     const touchY = e.touches[0].clientY;
// //     ctxRef.current.beginPath();
// //     ctxRef.current.moveTo(touchX, touchY);
// //   };

// //   // Handle touch move event for drawing
// //   const handleTouchMove = (e) => {
// //     e.preventDefault();
// //     if (!isCanvasReady || !ctxRef.current) return; // Check if canvas is ready
// //     const touchX = e.touches[0].clientX;
// //     const touchY = e.touches[0].clientY;
// //     ctxRef.current.lineTo(touchX, touchY);
// //     ctxRef.current.stroke(); // Draw the line
// //   };

// //   // Handle touch end event
// //   const handleTouchEnd = () => {
// //     if (!isCanvasReady || !ctxRef.current) return; // Check if canvas is ready
// //     ctxRef.current.closePath(); // Close the path when touch ends
// //   };

// //   return (
// //     <div>
// //       {!testStarted && <button onClick={startTest}>Start Screen Test</button>}

// //       {testStarted && !testResult && (
// //         <div
// //           onTouchStart={handleTouchStart}
// //           onTouchMove={handleTouchMove}
// //           onTouchEnd={handleTouchEnd}
// //           style={{
// //             width: "100vw",
// //             height: "100vh",
// //             overflow: "hidden",
// //             position: "relative", // Make sure other elements don't overlap
// //           }}
// //         >
// //           <canvas
// //             ref={canvasRef}
// //             width={window.innerWidth}
// //             height={window.innerHeight}
// //             style={{
// //               position: "absolute",
// //               top: 0,
// //               left: 0,
// //               pointerEvents: "none", // Canvas should not block other interactions
// //             }}
// //           ></canvas>
// //           <p
// //             style={{
// //               position: "absolute",
// //               top: "20px",
// //               left: "20px",
// //               color: "white",
// //             }}
// //           >
// //             Draw on the screen with your finger to test the touchscreen.
// //           </p>
// //         </div>
// //       )}

// //       {testResult === false && <p>Test failed. Screen might be damaged.</p>}
// //       {testResult === true && <p>Test passed. Screen is working fine.</p>}
// //     </div>
// //   );
// // };

// // export default ScreenTest;

// // // ••••••••••••••• Screen Code 5 Working on Mobile Device •••••••••••••••
// // import React, { useState, useRef, useEffect } from "react";

// // const ScreenTest = () => {
// //   const [testStarted, setTestStarted] = useState(false);
// //   const [testResult, setTestResult] = useState(null);
// //   const canvasRef = useRef(null);
// //   const ctxRef = useRef(null); // Ref to hold the canvas context
// //   const [isCanvasReady, setIsCanvasReady] = useState(false); // To track if canvas is ready
// //   const [drawingPath, setDrawingPath] = useState([]); // To store the drawing path

// //   console.log("347 ==== isCanvasReady ====", isCanvasReady);
// //   console.log("348 ==== testStarted ====", testStarted);
// //   console.log("349 ==== testResult ====", testResult);

// //   const startTest = () => {
// //     setTestStarted(true);
// //     setTestResult(null);
// //   };

// //   // Initialize canvas context once the canvas is available
// //   useEffect(() => {
// //     if (canvasRef.current) {
// //       const canvas = canvasRef.current;
// //       const ctx = canvas.getContext("2d");
// //       ctxRef.current = ctx;
// //       ctxRef.current.strokeStyle = "#00F"; // Color of the drawing (blue)
// //       ctxRef.current.lineWidth = 10; // Width of the stroke
// //       ctxRef.current.lineCap = "round"; // Smooth edges for the drawing
// //       setIsCanvasReady(true); // Set canvas ready flag

// //       // Retrieve stored drawing path from localStorage and redraw
// //       const storedPath = JSON.parse(localStorage.getItem("drawingPath")) || [];
// //       setDrawingPath(storedPath); // Set path to state
// //       redrawCanvas(storedPath); // Redraw the canvas with the saved path
// //     }
// //   }, []);

// //   // Function to redraw the canvas using the stored path
// //   const redrawCanvas = (path) => {
// //     if (ctxRef.current) {
// //       ctxRef.current.clearRect(
// //         0,
// //         0,
// //         canvasRef.current.width,
// //         canvasRef.current.height
// //       ); // Clear the canvas
// //       ctxRef.current.beginPath();
// //       path.forEach((point, index) => {
// //         if (index === 0) {
// //           ctxRef.current.moveTo(point.x, point.y);
// //         } else {
// //           ctxRef.current.lineTo(point.x, point.y);
// //         }
// //       });
// //       ctxRef.current.stroke(); // Redraw the line
// //     }
// //   };

// //   // Handle touch start event
// //   const handleTouchStart = (e) => {
// //     e.preventDefault();
// //     if (!isCanvasReady || !ctxRef.current) return; // Check if canvas is ready
// //     const touchX = e.touches[0].clientX;
// //     const touchY = e.touches[0].clientY;
// //     ctxRef.current.beginPath();
// //     ctxRef.current.moveTo(touchX, touchY);

// //     // Start a new drawing path
// //     setDrawingPath((prevPath) => {
// //       const newPath = [...prevPath, { x: touchX, y: touchY }];
// //       localStorage.setItem("drawingPath", JSON.stringify(newPath)); // Save the path to localStorage
// //       return newPath;
// //     });
// //   };

// //   // Handle touch move event for drawing
// //   const handleTouchMove = (e) => {
// //     e.preventDefault();
// //     if (!isCanvasReady || !ctxRef.current) return; // Check if canvas is ready
// //     const touchX = e.touches[0].clientX;
// //     const touchY = e.touches[0].clientY;
// //     ctxRef.current.lineTo(touchX, touchY);
// //     ctxRef.current.stroke(); // Draw the line

// //     // Add to the drawing path
// //     setDrawingPath((prevPath) => {
// //       const newPath = [...prevPath, { x: touchX, y: touchY }];
// //       localStorage.setItem("drawingPath", JSON.stringify(newPath)); // Save the path to localStorage
// //       return newPath;
// //     });
// //   };

// //   // Handle touch end event
// //   const handleTouchEnd = () => {
// //     if (!isCanvasReady || !ctxRef.current) return; // Check if canvas is ready
// //     ctxRef.current.closePath(); // Close the path when touch ends
// //   };

// //   return (
// //     <div>
// //       {!testStarted && <button onClick={startTest}>Start Screen Test 4</button>}

// //       {testStarted && !testResult && (
// //         <div
// //           onTouchStart={handleTouchStart}
// //           onTouchMove={handleTouchMove}
// //           onTouchEnd={handleTouchEnd}
// //           style={{
// //             width: "100vw",
// //             height: "100vh",
// //             overflow: "hidden",
// //             position: "relative", // Make sure other elements don't overlap
// //           }}
// //         >
// //           <canvas
// //             ref={canvasRef}
// //             width={window.innerWidth}
// //             height={window.innerHeight}
// //             style={{
// //               position: "absolute",
// //               top: 0,
// //               left: 0,
// //               pointerEvents: "none", // Canvas should not block other interactions
// //             }}
// //           ></canvas>
// //           <p
// //             style={{
// //               position: "absolute",
// //               top: "20px",
// //               left: "20px",
// //               color: "white",
// //             }}
// //           >
// //             Draw on the screen with your finger to test the touchscreen.
// //           </p>
// //         </div>
// //       )}

// //       {testResult === false && <p>Test failed. Screen might be damaged.</p>}
// //       {testResult === true && <p>Test passed. Screen is working fine.</p>}
// //     </div>
// //   );
// // };

// // export default ScreenTest;

// // ••••••••••••••• Screen Code 6 Test for Both Laptop and Mobile •••••••••••••••
// // import React, { useState, useRef, useEffect } from "react";

// // const ScreenTest = () => {
// //   const [testStarted, setTestStarted] = useState(false);
// //   const canvasRef = useRef(null);
// //   const ctxRef = useRef(null);

// //   const startTest = () => {
// //     setTestStarted(true);
// //   };

// //   useEffect(() => {
// //     if (canvasRef.current) {
// //       const canvas = canvasRef.current;
// //       const ctx = canvas.getContext("2d");
// //       ctxRef.current = ctx;

// //       // Set canvas drawing properties
// //       ctx.strokeStyle = "#0000FF"; // Blue color
// //       ctx.lineWidth = 5; // Stroke width
// //       ctx.lineCap = "round"; // Rounded stroke ends
// //     }
// //   }, []);

// //   const getTouchPos = (canvas, touch) => {
// //     const rect = canvas.getBoundingClientRect();
// //     return {
// //       x: touch.clientX - rect.left,
// //       y: touch.clientY - rect.top,
// //     };
// //   };

// //   const handleTouchStart = (e) => {
// //     e.preventDefault(); // Prevent default scrolling or touch interactions
// //     const touch = e.touches[0];
// //     const touchPos = getTouchPos(canvasRef.current, touch);

// //     if (ctxRef.current) {
// //       ctxRef.current.beginPath(); // Start a new path
// //       ctxRef.current.moveTo(touchPos.x, touchPos.y); // Move to the touch start point
// //     }
// //   };

// //   const handleTouchMove = (e) => {
// //     e.preventDefault(); // Prevent scrolling
// //     const touch = e.touches[0];
// //     const touchPos = getTouchPos(canvasRef.current, touch);

// //     if (ctxRef.current) {
// //       ctxRef.current.lineTo(touchPos.x, touchPos.y); // Draw a line to the new touch position
// //       ctxRef.current.stroke(); // Render the stroke
// //     }
// //   };

// //   const handleTouchEnd = () => {
// //     if (ctxRef.current) {
// //       ctxRef.current.closePath(); // End the path
// //     }
// //   };

// //   return (
// //     <div>
// //       {!testStarted && (
// //         <button onClick={startTest} style={{ margin: "20px", padding: "10px" }}>
// //           Start Screen Test
// //         </button>
// //       )}

// //       {testStarted && (
// //         <div
// //           style={{
// //             width: "100vw",
// //             height: "100vh",
// //             position: "relative",
// //             overflow: "hidden",
// //           }}
// //         >
// //           <canvas
// //             ref={canvasRef}
// //             width={window.innerWidth}
// //             height={window.innerHeight}
// //             style={{
// //               position: "absolute",
// //               top: 0,
// //               left: 0,
// //               backgroundColor: "#f8f8f8", // Light background for contrast
// //             }}
// //             onTouchStart={handleTouchStart}
// //             onTouchMove={handleTouchMove}
// //             onTouchEnd={handleTouchEnd}
// //           ></canvas>
// //           <p
// //             style={{
// //               position: "absolute",
// //               top: "20px",
// //               left: "20px",
// //               color: "black",
// //               fontSize: "18px",
// //             }}
// //           >
// //             Swipe your finger on the screen to draw.
// //           </p>
// //         </div>
// //       )}
// //     </div>
// //   );
// // };

// // export default ScreenTest;

// // ••••••••••••••• Screen Code 7 26 Nov Work Late •••••••••••••••
// // import React, { useState, useRef, useEffect } from "react";

// // const ScreenTest = () => {
// //   const [testStarted, setTestStarted] = useState(false);
// //   const canvasRef = useRef(null);
// //   const ctxRef = useRef(null);

// //   const startTest = () => {
// //     setTestStarted(true);
// //   };

// //   useEffect(() => {
// //     if (canvasRef.current) {
// //       const canvas = canvasRef.current;
// //       const ctx = canvas.getContext("2d");
// //       ctxRef.current = ctx;

// //       // Set canvas drawing properties
// //       ctx.strokeStyle = "#0000FF"; // Blue color
// //       ctx.lineWidth = 5; // Stroke width
// //       ctx.lineCap = "round"; // Rounded stroke ends
// //     }
// //   }, []);

// //   const getTouchPos = (canvas, touch) => {
// //     const rect = canvas.getBoundingClientRect();
// //     return {
// //       x: touch.clientX - rect.left,
// //       y: touch.clientY - rect.top,
// //     };
// //   };

// //   const handleTouchStart = (e) => {
// //     e.preventDefault(); // Prevent default scrolling or touch interactions
// //     const touch = e.touches[0];
// //     const touchPos = getTouchPos(canvasRef.current, touch);

// //     if (ctxRef.current) {
// //       ctxRef.current.beginPath(); // Start a new path
// //       ctxRef.current.moveTo(touchPos.x, touchPos.y); // Move to the touch start point
// //     }
// //   };

// //   const handleTouchMove = (e) => {
// //     e.preventDefault(); // Prevent scrolling
// //     const touch = e.touches[0];
// //     const touchPos = getTouchPos(canvasRef.current, touch);

// //     if (ctxRef.current) {
// //       ctxRef.current.lineTo(touchPos.x, touchPos.y); // Draw a line to the new touch position
// //       ctxRef.current.stroke(); // Render the stroke
// //     }
// //   };

// //   const handleTouchEnd = () => {
// //     if (ctxRef.current) {
// //       ctxRef.current.closePath(); // End the path
// //     }
// //   };

// //   return (
// //     <div>
// //       {!testStarted && (
// //         <button onClick={startTest} style={{ margin: "20px", padding: "10px" }}>
// //           Start Screen Test
// //         </button>
// //       )}

// //       {testStarted && (
// //         <div
// //           style={{
// //             width: "100vw",
// //             height: "100vh",
// //             position: "relative",
// //             overflow: "hidden",
// //           }}
// //         >
// //           <canvas
// //             ref={canvasRef}
// //             width={window.innerWidth}
// //             height={window.innerHeight}
// //             style={{
// //               position: "absolute",
// //               top: 0,
// //               left: 0,
// //               // backgroundColor: "#f8f8f8", // Light background for contrast
// //               backgroundColor: "red", // Light background for contrast
// //             }}
// //             onTouchStart={handleTouchStart}
// //             onTouchMove={handleTouchMove}
// //             onTouchEnd={handleTouchEnd}
// //           ></canvas>
// //           <p
// //             style={{
// //               position: "absolute",
// //               top: "20px",
// //               left: "20px",
// //               color: "black",
// //               fontSize: "18px",
// //             }}
// //           >
// //             Swipe your finger on the screen to draw.
// //           </p>
// //         </div>
// //       )}
// //     </div>
// //   );
// // };

// // export default ScreenTest;

// // ••••••••••••••• Screen Code 8 •••••••••••••••
// import React, { useState, useRef, useEffect } from "react";

// const ScreenTest = () => {
//   const [testStarted, setTestStarted] = useState(false);
//   const canvasRef = useRef(null);
//   const ctxRef = useRef(null);
//   const isDrawingRef = useRef(false); // Track if the user is currently drawing

//   const startTest = () => {
//     setTestStarted(true);
//   };

//   useEffect(() => {
//     if (canvasRef.current) {
//       const canvas = canvasRef.current;
//       const ctx = canvas.getContext("2d");
//       ctxRef.current = ctx;

//       // Configure canvas properties
//       ctx.strokeStyle = "#0000FF"; // Blue color
//       ctx.lineWidth = 10; // Line width
//       ctx.lineCap = "round"; // Rounded ends for smooth drawing
//     }
//   }, []);

//   const getTouchPos = (canvas, touch) => {
//     const rect = canvas.getBoundingClientRect();
//     return {
//       x: touch.clientX - rect.left,
//       y: touch.clientY - rect.top,
//     };
//   };

//   const handleTouchStart = (e) => {
//     e.preventDefault(); // Prevent default scrolling or touch interactions
//     isDrawingRef.current = true; // Start drawing
//     const touchPos = getTouchPos(canvasRef.current, e.touches[0]);

//     if (ctxRef.current) {
//       ctxRef.current.beginPath(); // Begin a new path
//       ctxRef.current.moveTo(touchPos.x, touchPos.y); // Move to the touch position
//     }
//   };

//   const handleTouchMove = (e) => {
//     if (!isDrawingRef.current) return; // Only draw if the user is touching
//     e.preventDefault(); // Prevent scrolling
//     const touchPos = getTouchPos(canvasRef.current, e.touches[0]);

//     if (ctxRef.current) {
//       ctxRef.current.lineTo(touchPos.x, touchPos.y); // Draw to the new position
//       ctxRef.current.stroke(); // Render the stroke
//     }
//   };

//   const handleTouchEnd = () => {
//     if (ctxRef.current) {
//       ctxRef.current.closePath(); // Finish the current path
//     }
//     isDrawingRef.current = false; // Stop drawing
//   };

//   return (
//     <div>
//       {!testStarted && (
//         <button
//           onClick={startTest}
//           style={{
//             margin: "20px",
//             padding: "10px",
//             fontSize: "16px",
//           }}
//         >
//           Start Screen Testing
//         </button>
//       )}

//       {testStarted && (
//         <div
//           style={{
//             width: "100vw",
//             height: "100vh",
//             position: "relative",
//             overflow: "hidden",
//           }}
//         >
//           <canvas
//             ref={canvasRef}
//             width={window.innerWidth}
//             height={window.innerHeight}
//             style={{
//               position: "absolute",
//               top: 0,
//               left: 0,
//               backgroundColor: "#f0f0f0", // Light background for contrast
//             }}
//             onTouchStart={handleTouchStart}
//             onTouchMove={handleTouchMove}
//             onTouchEnd={handleTouchEnd}
//           ></canvas>
//           <p
//             style={{
//               position: "absolute",
//               top: "20px",
//               left: "20px",
//               color: "black",
//               fontSize: "18px",
//             }}
//           >
//             Swipe your finger to color the screen.
//           </p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ScreenTest;

// // ============== Screen Analyze By Pics ============
// import React, { useRef, useState } from "react";
// import Webcam from "react-webcam";

// const App = () => {
//   const webcamRef = useRef(null);
//   const [capturedImage, setCapturedImage] = useState(null);
//   const [result, setResult] = useState("");

//   // Capture the image from the webcam
//   const captureImage = () => {
//     const imageSrc = webcamRef.current.getScreenshot();
//     setCapturedImage(imageSrc); // Save the captured image for processing
//   };

//   // Analyze the captured image
//   const analyzeImage = () => {
//     if (!capturedImage) {
//       alert("Please capture an image first.");
//       return;
//     }

//     const imgElement = document.createElement("img");
//     imgElement.src = capturedImage;
//     imgElement.onload = () => {
//       processImage(imgElement);
//     };
//   };

//   // Process the image using OpenCV.js
//   const processImage = (imgElement) => {
//     try {
//       const src = cv.imread(imgElement); // Load image into OpenCV
//       const gray = new cv.Mat();
//       cv.cvtColor(src, gray, cv.COLOR_RGBA2GRAY); // Convert to grayscale

//       // Example: Detect edges using Canny (simulate damage detection)
//       const edges = new cv.Mat();
//       cv.Canny(gray, edges, 50, 150);

//       // Analyze the edges for damage patterns (simplified example)
//       const nonZero = cv.countNonZero(edges);
//       if (nonZero > 10000) {
//         // Example threshold
//         setResult("Screen damage detected.");
//       } else {
//         setResult("No damage detected.");
//       }

//       // Clean up OpenCV.js resources
//       src.delete();
//       gray.delete();
//       edges.delete();
//     } catch (err) {
//       console.error("Error processing image: ", err);
//       setResult("Error analyzing the image.");
//     }
//   };

//   return (
//     <div style={{ textAlign: "center" }}>
//       <h1>Screen Damage Detector</h1>
//       <Webcam
//         audio={false}
//         ref={webcamRef}
//         screenshotFormat="image/jpeg"
//         width={400}
//         style={{ marginBottom: "20px" }}
//       />
//       <div>
//         <button onClick={captureImage} style={{ marginRight: "10px" }}>
//           Capture Image
//         </button>
//         <button onClick={analyzeImage}>Analyze for Damage</button>
//       </div>
//       {capturedImage && (
//         <div style={{ marginTop: "20px" }}>
//           <h3>Captured Image:</h3>
//           <img
//             src={capturedImage}
//             alt="Captured"
//             style={{ width: "400px", border: "1px solid black" }}
//           />
//         </div>
//       )}
//       {result && (
//         <div style={{ marginTop: "20px", color: "blue" }}>
//           <h3>Result:</h3>
//           <p>{result}</p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default App;

// ********************* 02 Dec Single Page Code *********************
// import React from "react";
// import ScreenSwapDamageTest from "./ScreenSwapDamageTest";
// import XRayScreenDamageScanner from "./XRayScreenDamageScanner";
// import ImeiDetectFromVideo from "./ImeiDetectFromVideo";
// import Routes from "./Routes/Routes";

// const App = () => {
//   return (
//     <div>
//       <Routes />
//       {/* ****** Swap Screen To Check Damage Screen Test Page ****** */}
//       {/* <ScreenSwapDamageTest /> */}

//       {/* ****** X-Ray Screen Scan To Check Damage Screen Test Page ****** */}
//       {/* <XRayScreenDamageScanner /> */}

//       {/* ****** Swap Screen Test Page ****** */}
//       {/* <ImeiDetectFromVideo /> */}
//     </div>
//   );
// };

// export default App;

// ********************* 04 Feb With Page Routing Code *********************
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RoutesPage from "./Routes";
import IMEIDetect from "./ImeiDetectFromVideo";
import ScreenSwap from "./ScreenSwapDamageTest";
import XRayTest from "./XRayScreenDamageScanner";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RoutesPage />} />
        <Route path="/imei-detect" element={<IMEIDetect />} />
        <Route path="/screen-swap" element={<ScreenSwap />} />
        <Route path="/xray-test" element={<XRayTest />} />
      </Routes>
    </Router>
  );
};

export default App;
