// // import React, { useState, useEffect } from "react";
// // import "./XRayScanner.css";

// // const XRayScreenDamageScanner = () => {
// //   const [scanning, setScanning] = useState(false);

// //   const startScan = () => {
// //     setScanning(true);
// //     setTimeout(() => {
// //       setScanning(false);
// //     }, 3000); // Duration of the scan
// //   };

// //   return (
// //     <div className="scanner-container">
// //       <div className="image-wrapper">
// //         <img
// //           src="your-image.jpg"
// //           alt="Scannable Content"
// //           className="scan-image"
// //         />
// //         {scanning && <div className="scanner-bar"></div>}
// //       </div>
// //       <button className="scan-button" onClick={startScan}>
// //         Start Scan
// //       </button>
// //     </div>
// //   );
// // };

// // export default XRayScreenDamageScanner;

// ------> WORKING CODE <------
// import React, { useState } from "react";
// import axios from "axios";
// import PhotoCapture from "./PhotoCapture";
// import { Box, Button } from "@mui/material";

// const ScreenDamageChecker = () => {
//   const [result, setResult] = useState("");
//   const [photo, setPhoto] = useState(null);

//   const analyzePhoto = async (capturedPhoto) => {
//     try {
//       const response = await axios.post("https://api.example.com/analyze", {
//         image: capturedPhoto,
//       });
//       setResult(response.data.result); // e.g., "Screen is damaged" or "Screen is intact"
//     } catch (error) {
//       console.error("Error analyzing photo:", error);
//       setResult("Error analyzing photo. Try again.");
//     }
//   };

//   const handleCapture = (capturedPhoto) => {
//     setPhoto(capturedPhoto);
//     analyzePhoto(capturedPhoto);
//   };

//   return (
//     <div>
//       <h1>Screen Damage Checker</h1>
//       {!photo ? (
//         <PhotoCapture onCapture={handleCapture} />
//       ) : (
//         <div>
//           <img src={photo} alt="Captured screen" style={{ width: "100%" }} />
//           <p>Result: {result}</p>
//           {/* <button onClick={() => setPhoto(null)}>Retake Photo</button> */}
//           <Box
//             sx={{
//               p: 2,
//               gap: 2,
//               display: "flex",
//               flexDirection: "column",
//               width: { xs: "90%", sm: "60%", md: "40%", lg: "30%" },
//             }}
//           >
//             <Button
//               fullWidth
//               variant="contained"
//               color="secondary"
//               onClick={() => setPhoto(null)}
//             >
//               Retake Photo
//             </Button>
//           </Box>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ScreenDamageChecker;

// ------------ TESTING CODE --------------
// import React, { useState } from "react";
// import QRCodeScanner from "react-qr-reader";
// import { QrReader } from "react-qr-reader"; // Use named export QrReader

// const QRScan = () => {
//   const [scanResult, setScanResult] = useState(""); // Store scan result

//   // Handle when QR code is successfully scanned
//   const handleScan = (data) => {
//     if (data) {
//       // If QR code is scanned correctly, show success
//       setScanResult(data);
//       alert("Success: QR Code scanned successfully!");
//     }
//   };

//   // Handle when scanning fails or if there is an error
//   const handleError = (err) => {
//     console.error(err);
//     alert("Error: Failed to scan QR code.");
//   };

//   return (
//     <div style={{ textAlign: "center", paddingTop: "20px" }}>
//       <h2>QR Code Scanner</h2>
//       <QrReader
//         delay={300}
//         style={{ width: "60%" }}
//         onError={handleError} // Handle error
//         onScan={handleScan} // Handle successful scan
//       />
//       <p>{scanResult ? `Scanned Data: ${scanResult}` : "Scan a QR code"}</p>
//     </div>
//   );
// };

// export default QRScan;

// ~~~~~~~~~~~~> Modification of Above Code <~~~~~~~~~~~~
// import React, { useState } from "react";
// import { QrReader } from "react-qr-reader"; // Use named export QrReader
// import "./styles.css"; // Import the CSS file

// const QRScan = () => {
//   const [scanResult, setScanResult] = useState(""); // Store scan result
//   const [isScanning, setIsScanning] = useState(false); // State to toggle the QR scanner visibility

//   // Handle when QR code is successfully scanned
//   const handleScan = (data) => {
//     if (data) {
//       // If QR code is scanned correctly, show success
//       setScanResult(data);
//       alert("Success: QR Code scanned successfully!");
//     }
//   };

//   // Handle when scanning fails or if there is an error
//   const handleError = (err) => {
//     console.error(err);
//     alert("Error: Failed to scan QR code.");
//   };

//   // Toggle scanning (if user wants to stop scanning)
//   const toggleScan = () => {
//     setIsScanning((prevState) => !prevState); // Toggle scanning status
//   };

//   return (
//     <div className="qr-scan-container">
//       <h2>QR Code Scanner</h2>

//       {/* Conditional rendering for starting/stopping the scanner */}
//       {isScanning ? (
//         <QrReader
//           delay={300}
//           style={{ width: "100%", maxWidth: "500px" }} // Responsive QR scanner size
//           onError={handleError} // Handle error
//           onScan={handleScan} // Handle successful scan
//         />
//       ) : (
//         <p>Scanner is off. Click below to start scanning.</p>
//       )}

//       <p>{scanResult ? `Scanned Data: ${scanResult}` : "Scan a QR code"}</p>

//       <button onClick={toggleScan} style={buttonStyle}>
//         {isScanning ? "Stop Scanning" : "Start Scanning"}
//       </button>
//     </div>
//   );
// };

// // Style for the button
// const buttonStyle = {
//   padding: "10px 20px",
//   backgroundColor: "#4CAF50",
//   color: "white",
//   border: "none",
//   borderRadius: "5px",
//   cursor: "pointer",
//   fontSize: "16px",
//   marginTop: "20px",
// };

// export default QRScan;
import React, { useState } from "react";
import { QrReader } from "react-qr-reader"; // Use named export QrReader
import "./styles.css"; // Import the CSS file

const QRScan = () => {
  const [scanResult, setScanResult] = useState(""); // Store scan result
  const [isScanning, setIsScanning] = useState(false); // State to toggle the QR scanner visibility

  // Handle when QR code is successfully scanned
  const handleScan = (data) => {
    if (data) {
      // If QR code is scanned correctly, show success
      setScanResult(data);
      alert("Success: QR Code scanned successfully!");
    }
  };

  // Handle when scanning fails or if there is an error
  const handleError = (err) => {
    console.error(err);
    alert("Error: Failed to scan QR code.");
  };

  // Toggle scanning (if user wants to stop scanning)
  const toggleScan = () => {
    setIsScanning((prevState) => !prevState); // Toggle scanning status
  };

  return (
    <div className="qr-scan-container">
      <h2>QR Code Scanner</h2>

      {/* Conditional rendering for starting/stopping the scanner */}
      {isScanning ? (
        <QrReader
          delay={300}
          style={{ width: "100%", maxWidth: "500px" }} // Responsive QR scanner size
          onError={handleError} // Handle error
          onScan={handleScan} // Handle successful scan
        />
      ) : (
        <p>Scanner is off. Click below to start scanning.</p>
      )}

      <p>{scanResult ? `Scanned Data: ${scanResult}` : "Scan a QR code"}</p>

      <button onClick={toggleScan} style={buttonStyle}>
        {isScanning ? "Stop Scanning" : "Start Scanning"}
      </button>
    </div>
  );
};

// Style for the button
const buttonStyle = {
  padding: "10px 20px",
  backgroundColor: "#4CAF50",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "16px",
  marginTop: "20px",
};

export default QRScan;
