// // ****** Testing 1 --> Imei Detect With Video ******
// import React, { useRef, useState } from "react";
// import Tesseract from "tesseract.js";

// const ImeiDetectFromVideo = () => {
//   const videoRef = useRef(null);
//   const canvasRef = useRef(null);
//   const [imei, setImei] = useState("");
//   const [isRecording, setIsRecording] = useState(false);
//   const mediaRecorderRef = useRef(null);
//   const [recordedChunks, setRecordedChunks] = useState([]);

//   // Start camera
//   const startCamera = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({
//         video: { facingMode: "environment" }, // Use back camera
//       });
//       if (videoRef.current) {
//         videoRef.current.srcObject = stream;
//         videoRef.current.play();
//         startRecording(stream);
//       }
//     } catch (error) {
//       console.error("Error accessing camera:", error);
//     }
//   };

//   // Start recording the video
//   const startRecording = (stream) => {
//     const mediaRecorder = new MediaRecorder(stream);
//     mediaRecorderRef.current = mediaRecorder;
//     const chunks = [];
//     mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
//     mediaRecorder.onstop = () => {
//       setRecordedChunks(chunks);
//       // Optionally, you can save the recorded video or process the chunks further
//     };
//     mediaRecorder.start();
//     setIsRecording(true);
//   };

//   // Stop recording the video
//   const stopRecording = () => {
//     if (
//       mediaRecorderRef.current &&
//       mediaRecorderRef.current.state !== "inactive"
//     ) {
//       mediaRecorderRef.current.stop();
//       setIsRecording(false);
//     }
//   };

//   // Capture frame from video stream and detect IMEI
//   const captureFrameAndDetectIMEI = async () => {
//     const video = videoRef.current;
//     const canvas = canvasRef.current;

//     if (video && canvas) {
//       const context = canvas.getContext("2d");
//       canvas.width = video.videoWidth;
//       canvas.height = video.videoHeight;

//       // Draw video frame onto canvas
//       context.drawImage(video, 0, 0, canvas.width, canvas.height);

//       // Extract text using Tesseract.js
//       const imageData = canvas.toDataURL("image/png");
//       Tesseract.recognize(imageData, "eng", {
//         logger: (info) => console.log(info), // Progress logging
//       }).then(({ data: { text } }) => {
//         console.log("Detected Text:", text);

//         // Validate and extract IMEI (15-digit numeric)
//         const imeiMatch = text.match(/\b\d{15}\b/);
//         if (imeiMatch) {
//           setImei(imeiMatch[0]);
//         } else {
//           setImei("No IMEI detected");
//         }
//       });
//     }
//   };

//   return (
//     <div>
//       <h1>IMEI Detector</h1>
//       <video ref={videoRef} style={{ width: "400px", height: "400px" }} />
//       <canvas ref={canvasRef} style={{ display: "none" }} />
//       {!isRecording ? (
//         <button onClick={startCamera}>Start Camera and Recording</button>
//       ) : (
//         <button onClick={stopRecording}>Stop Recording</button>
//       )}
//       <button onClick={captureFrameAndDetectIMEI}>
//         Capture Frame and Detect IMEI
//       </button>
//       {imei && <h2>Detected IMEI: {imei}</h2>}
//     </div>
//   );
// };

// export default ImeiDetectFromVideo;

// ****** Testing 2 --> Imei Detect With Video & Video Preview ******
import Tesseract from "tesseract.js";
import React, { useRef, useState } from "react";
import { Button, Box, Container } from "@mui/material";

const ImeiDetectFromVideo = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [imei, setImei] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [videoURL, setVideoURL] = useState("");

  // Start camera
  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "environment" }, // Use back camera
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
        startRecording(stream);
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  // Start recording the video
  const startRecording = (stream) => {
    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorderRef.current = mediaRecorder;
    const chunks = [];
    mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
    mediaRecorder.onstop = () => {
      setRecordedChunks(chunks);
      // Create a Blob from the recorded chunks and generate a URL to preview the video
      const blob = new Blob(chunks, { type: "video/webm" });
      const videoUrl = URL.createObjectURL(blob);
      setVideoURL(videoUrl);
    };
    mediaRecorder.start();
    setIsRecording(true);
  };

  // Stop recording the video
  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  // Capture frame from video stream and detect IMEI
  const captureFrameAndDetectIMEI = async () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    if (video && canvas) {
      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Draw video frame onto canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Extract text using Tesseract.js
      const imageData = canvas.toDataURL("image/png");
      Tesseract.recognize(imageData, "eng", {
        logger: (info) => console.log(info), // Progress logging
      }).then(({ data: { text } }) => {
        console.log("Detected Text:", text);

        // Validate and extract IMEI (15-digit numeric)
        const imeiMatch = text.match(/\b\d{15}\b/);
        if (imeiMatch) {
          setImei(imeiMatch[0]);
        } else {
          setImei("No IMEI detected");
        }
      });
    }
  };

  return (
    <Container
      // maxWidth="lg"
      sx={{
        // display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f3f4f6",
        px: { xs: 2, sm: 4, md: 6 },
      }}
    >
      <video ref={videoRef} style={{ width: "400px", height: "400px" }} />
      <canvas ref={canvasRef} style={{ display: "none" }} />

      {/* {!isRecording ? (
        <button onClick={startCamera}>Start Camera and Recording</button>
      ) : (
        <button onClick={stopRecording}>Stop Recording</button>
      )}
      <button onClick={captureFrameAndDetectIMEI}>
        Capture Frame and Detect IMEI
      </button> */}

      <Box
        sx={{
          p: 2,
          gap: 2,
          display: "flex",
          flexDirection: "row",
          width: { xs: "90%", sm: "60%", md: "40%", lg: "30%" },
        }}
      >
        {!isRecording ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => startCamera()}
            style={{ width: "50%" }}
          >
            Start Rec
          </Button>
        ) : (
          <Button
            style={{ width: "50%" }}
            variant="contained"
            color="warning"
            onClick={() => stopRecording()}
          >
            Stop Rec
          </Button>
        )}

        <Button
          style={{ width: "50%" }}
          variant="contained"
          color="success"
          onClick={() => captureFrameAndDetectIMEI()}
        >
          Detect IMEI
        </Button>
      </Box>
      {imei && <h4>Detected IMEI: {imei}</h4>}

      {/* {videoURL && (
        <div>
          <h2>Preview Recorded Video:</h2>
          <video src={videoURL} controls width="100%" />
          <a href={videoURL} download="recorded-video.webm">
            <button>Download Video</button>
          </a>
        </div>
      )} */}
    </Container>
  );
};

export default ImeiDetectFromVideo;

// ****** Working IMEI Detection from Video/Image 04 Feb --> 1st ******
// import Tesseract from "tesseract.js";
// import React, { useRef, useState } from "react";
// import { Button, Box, Container } from "@mui/material";

// const ImeiDetectFromVideo = () => {
//   const videoRef = useRef(null);
//   const canvasRef = useRef(null);
//   const [imei, setImei] = useState("");
//   const [isRecording, setIsRecording] = useState(false);
//   const mediaRecorderRef = useRef(null);
//   const [videoURL, setVideoURL] = useState("");

//   // Start camera and recording
//   const startCamera = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({
//         video: { facingMode: "environment", width: 1280, height: 720 },
//       });
//       if (videoRef.current) {
//         videoRef.current.srcObject = stream;
//         videoRef.current.play();
//         startRecording(stream);
//       }
//     } catch (error) {
//       console.error("Error accessing camera:", error);
//     }
//   };

//   // Start video recording
//   const startRecording = (stream) => {
//     const mediaRecorder = new MediaRecorder(stream);
//     mediaRecorderRef.current = mediaRecorder;
//     const chunks = [];
//     mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
//     mediaRecorder.onstop = () => {
//       const blob = new Blob(chunks, { type: "video/webm" });
//       setVideoURL(URL.createObjectURL(blob));
//     };
//     mediaRecorder.start();
//     setIsRecording(true);
//   };

//   // Stop video recording
//   const stopRecording = () => {
//     if (
//       mediaRecorderRef.current &&
//       mediaRecorderRef.current.state !== "inactive"
//     ) {
//       mediaRecorderRef.current.stop();
//       setIsRecording(false);
//     }
//   };

//   // Validate IMEI using the Luhn algorithm
//   const isValidIMEI = (imei) => {
//     let sum = 0;
//     for (let i = 0; i < 15; i++) {
//       let digit = parseInt(imei[i]);
//       if (i % 2 !== 0) {
//         digit *= 2;
//         if (digit > 9) digit -= 9;
//       }
//       sum += digit;
//     }
//     return sum % 10 === 0;
//   };

//   // Capture frame from video and detect IMEI
//   const captureFrameAndDetectIMEI = async () => {
//     const video = videoRef.current;
//     const canvas = canvasRef.current;

//     if (video && canvas) {
//       const context = canvas.getContext("2d");
//       canvas.width = 1280;
//       canvas.height = 720;

//       // Enhance video frame contrast
//       context.drawImage(video, 0, 0, canvas.width, canvas.height);
//       const imageData = canvas.toDataURL("image/png");

//       try {
//         const {
//           data: { text },
//         } = await Tesseract.recognize(imageData, "eng", {
//           logger: (m) => console.log(m),
//         });

//         console.log("OCR Detected Text:", text);

//         // Extract possible IMEI
//         const imeiMatch = text.match(/\b\d{15}\b/g);
//         if (imeiMatch) {
//           const validImei = imeiMatch.find(isValidIMEI);
//           setImei(validImei || "No valid IMEI detected");
//         } else {
//           setImei("No IMEI detected");
//         }
//       } catch (error) {
//         console.error("Tesseract Error:", error);
//       }
//     }
//   };

//   return (
//     <Container
//       maxWidth="lg"
//       sx={{
//         justifyContent: "center",
//         alignItems: "center",
//         minHeight: "100vh",
//         backgroundColor: "#f3f4f6",
//         px: { xs: 2, sm: 4, md: 6 },
//       }}
//     >
//       <h3>IMEI Detector</h3>
//       <video ref={videoRef} style={{ width: "400px" }} />
//       <canvas ref={canvasRef} style={{ display: "none" }} />

//       <Box
//         sx={{
//           p: 2,
//           gap: 2,
//           display: "flex",
//           flexDirection: "column",
//           width: { xs: "90%", sm: "60%", md: "40%", lg: "30%" },
//         }}
//       >
//         {!isRecording ? (
//           <Button
//             fullWidth
//             variant="contained"
//             color="secondary"
//             onClick={startCamera}
//           >
//             Start Camera and Recording
//           </Button>
//         ) : (
//           <Button
//             fullWidth
//             variant="contained"
//             color="warning"
//             onClick={stopRecording}
//           >
//             Stop Recording
//           </Button>
//         )}

//         <Button
//           fullWidth
//           variant="contained"
//           color="success"
//           onClick={captureFrameAndDetectIMEI}
//         >
//           Capture Frame and Detect IMEI
//         </Button>
//       </Box>

//       {imei && <h3>Detected IMEI: {imei}</h3>}

//       {videoURL && (
//         <div>
//           <h2>Preview Recorded Video:</h2>
//           <video src={videoURL} controls width="100%" />
//           <a href={videoURL} download="recorded-video.webm">
//             <button>Download Video</button>
//           </a>
//         </div>
//       )}
//     </Container>
//   );
// };
// export default ImeiDetectFromVideo;

// ______ Scroll Issues Fixed ___________________

// import Tesseract from "tesseract.js";
// import React, { useRef, useState } from "react";
// import { Button, Box, Container } from "@mui/material";

// const ImeiDetectFromVideo = () => {
//   const videoRef = useRef(null);
//   const canvasRef = useRef(null);
//   const [imei, setImei] = useState("");
//   const [isRecording, setIsRecording] = useState(false);
//   const mediaRecorderRef = useRef(null);
//   const [videoURL, setVideoURL] = useState("");

//   // Start camera and recording
//   const startCamera = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({
//         video: { facingMode: "environment", width: 1280, height: 720 },
//       });
//       if (videoRef.current) {
//         videoRef.current.srcObject = stream;
//         videoRef.current.play();
//         startRecording(stream);
//       }
//     } catch (error) {
//       console.error("Error accessing camera:", error);
//     }
//   };

//   // Start video recording
//   const startRecording = (stream) => {
//     const mediaRecorder = new MediaRecorder(stream);
//     mediaRecorderRef.current = mediaRecorder;
//     const chunks = [];
//     mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
//     mediaRecorder.onstop = () => {
//       const blob = new Blob(chunks, { type: "video/webm" });
//       setVideoURL(URL.createObjectURL(blob));
//     };
//     mediaRecorder.start();
//     setIsRecording(true);
//   };

//   // Stop video recording
//   const stopRecording = () => {
//     if (
//       mediaRecorderRef.current &&
//       mediaRecorderRef.current.state !== "inactive"
//     ) {
//       mediaRecorderRef.current.stop();
//       setIsRecording(false);
//     }
//   };

//   // Validate IMEI using the Luhn algorithm
//   const isValidIMEI = (imei) => {
//     let sum = 0;
//     for (let i = 0; i < 15; i++) {
//       let digit = parseInt(imei[i]);
//       if (i % 2 !== 0) {
//         digit *= 2;
//         if (digit > 9) digit -= 9;
//       }
//       sum += digit;
//     }
//     return sum % 10 === 0;
//   };

//   // Capture frame from video and detect IMEI
//   const captureFrameAndDetectIMEI = async () => {
//     const video = videoRef.current;
//     const canvas = canvasRef.current;

//     if (video && canvas) {
//       const context = canvas.getContext("2d");
//       canvas.width = 1280;
//       canvas.height = 720;

//       // Enhance video frame contrast
//       context.drawImage(video, 0, 0, canvas.width, canvas.height);
//       const imageData = canvas.toDataURL("image/png");

//       try {
//         const {
//           data: { text },
//         } = await Tesseract.recognize(imageData, "eng", {
//           logger: (m) => console.log(m),
//         });

//         console.log("OCR Detected Text:", text);

//         // Extract possible IMEI
//         const imeiMatch = text.match(/\b\d{15}\b/g);
//         if (imeiMatch) {
//           const validImei = imeiMatch.find(isValidIMEI);
//           setImei(validImei || "No valid IMEI detected");
//         } else {
//           setImei("No IMEI detected");
//         }
//       } catch (error) {
//         console.error("Tesseract Error:", error);
//       }
//     }
//   };

//   return (
//     <Container
//       maxWidth="lg"
//       sx={{
//         justifyContent: "center",
//         alignItems: "center",
//         height: "auto", // Allow content to expand based on screen size
//         backgroundColor: "#f3f4f6",
//         px: { xs: 2, sm: 4, md: 6 },
//         display: "flex", // Flexbox for alignment
//         flexDirection: "column", // Stack content vertically
//         overflowX: "hidden", // Prevent horizontal scrolling
//       }}
//     >
//       <h3>IMEI Detector</h3>
//       <video ref={videoRef} style={{ width: "100%", maxWidth: "400px" }} />
//       <canvas ref={canvasRef} style={{ display: "none" }} />

//       <Box
//         sx={{
//           p: 2,
//           gap: 2,
//           display: "flex",
//           flexDirection: "column",
//           width: "100%",
//           alignItems: "center", // Center button content
//         }}
//       >
//         {!isRecording ? (
//           <Button
//             variant="contained"
//             color="secondary"
//             onClick={startCamera}
//             style={{
//               position: "absolute",
//               top: "20px",
//               left: "10px",
//               // backgroundColor: "green",
//               color: "white",
//               padding: "10px 20px",
//               fontSize: "16px",
//               borderRadius: "5px",
//             }}
//           >
//             Start Recording
//           </Button>
//         ) : (
//           <Button
//             variant="contained"
//             color="warning"
//             onClick={stopRecording}
//             style={{
//               position: "absolute",
//               top: "20px",
//               left: "20px",
//               color: "white",
//               // padding: "10px 20px",
//               fontSize: "16px",
//               borderRadius: "5px",
//             }}
//           >
//             Stop Recording
//           </Button>
//         )}

//         <Button
//           variant="contained"
//           color="success"
//           onClick={captureFrameAndDetectIMEI}
//           style={{
//             width: "50%",
//             position: "absolute",
//             bottom: "20px",
//             left: "20px",
//             color: "white",
//             // padding: "10px 20px",
//             fontSize: "16px",
//             borderRadius: "5px",
//           }}
//         >
//           Capture Frame & Detect IMEI
//         </Button>
//       </Box>

//       {imei && <h3>Detected IMEI: {imei}</h3>}

//       {videoURL && (
//         <div>
//           <h2>Preview Recorded Video:</h2>
//           <video src={videoURL} controls width="100%" height="300px" />
//           <a href={videoURL} download="recorded-video.webm">
//             <button>Download Video</button>
//           </a>
//         </div>
//       )}
//     </Container>
//   );
// };

// export default ImeiDetectFromVideo;
// import Tesseract from "tesseract.js";
// import React, { useRef, useState } from "react";
// import { Button, Box, Container } from "@mui/material";

// const ImeiDetectFromVideo = () => {
//   const videoRef = useRef(null);
//   const canvasRef = useRef(null);
//   const [imei, setImei] = useState("");
//   const [isRecording, setIsRecording] = useState(false);
//   const mediaRecorderRef = useRef(null);
//   const [videoURL, setVideoURL] = useState("");

//   // Start camera and recording
//   const startCamera = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({
//         video: { facingMode: "environment", width: 1280, height: 720 },
//       });
//       if (videoRef.current) {
//         videoRef.current.srcObject = stream;
//         videoRef.current.play();
//         startRecording(stream);
//       }
//     } catch (error) {
//       console.error("Error accessing camera:", error);
//     }
//   };

//   // Start video recording
//   const startRecording = (stream) => {
//     const mediaRecorder = new MediaRecorder(stream);
//     mediaRecorderRef.current = mediaRecorder;
//     const chunks = [];
//     mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
//     mediaRecorder.onstop = () => {
//       const blob = new Blob(chunks, { type: "video/webm" });
//       setVideoURL(URL.createObjectURL(blob));
//     };
//     mediaRecorder.start();
//     setIsRecording(true);
//   };

//   // Stop video recording
//   const stopRecording = () => {
//     if (
//       mediaRecorderRef.current &&
//       mediaRecorderRef.current.state !== "inactive"
//     ) {
//       mediaRecorderRef.current.stop();
//       setIsRecording(false);
//     }
//   };

//   // Validate IMEI using the Luhn algorithm
//   const isValidIMEI = (imei) => {
//     let sum = 0;
//     for (let i = 0; i < 15; i++) {
//       let digit = parseInt(imei[i]);
//       if (i % 2 !== 0) {
//         digit *= 2;
//         if (digit > 9) digit -= 9;
//       }
//       sum += digit;
//     }
//     return sum % 10 === 0;
//   };

//   // Capture frame from video and detect IMEI
//   const captureFrameAndDetectIMEI = async () => {
//     const video = videoRef.current;
//     const canvas = canvasRef.current;

//     if (video && canvas) {
//       const context = canvas.getContext("2d");
//       canvas.width = 1280;
//       canvas.height = 720;

//       // Enhance video frame contrast
//       context.drawImage(video, 0, 0, canvas.width, canvas.height);
//       const imageData = canvas.toDataURL("image/png");

//       try {
//         const {
//           data: { text },
//         } = await Tesseract.recognize(imageData, "eng", {
//           logger: (m) => console.log(m),
//         });

//         console.log("OCR Detected Text:", text);

//         // Extract possible IMEI
//         const imeiMatch = text.match(/\b\d{15}\b/g);
//         if (imeiMatch) {
//           const validImei = imeiMatch.find(isValidIMEI);
//           setImei(validImei || "No valid IMEI detected");
//         } else {
//           setImei("No IMEI detected");
//         }
//       } catch (error) {
//         console.error("Tesseract Error:", error);
//       }
//     }
//   };

//   return (
//     <Container
//       maxWidth="lg"
//       sx={{
//         justifyContent: "center",
//         alignItems: "center",
//         height: "auto", // Allow content to expand based on screen size
//         backgroundColor: "#f3f4f6",
//         px: { xs: 2, sm: 4, md: 6 },
//         display: "flex", // Flexbox for alignment
//         flexDirection: "column", // Stack content vertically
//         overflowX: "hidden", // Prevent horizontal scrolling
//         overflowY: "auto", // Make it scrollable if content overflows vertically
//       }}
//     >
//       <h3>IMEI Detector</h3>
//       <video
//         ref={videoRef}
//         style={{
//           width: "100%",
//           maxWidth: "400px",
//           marginBottom: "20px",
//         }}
//       />
//       <canvas ref={canvasRef} style={{ display: "none" }} />

//       <Box
//         sx={{
//           p: 2,
//           gap: 2,
//           display: "flex",
//           flexDirection: "column",
//           width: "100%",
//           alignItems: "center", // Center button content
//           position: "relative", // To position buttons properly
//         }}
//       >
//         {!isRecording ? (
//           <Button
//             variant="contained"
//             color="secondary"
//             onClick={startCamera}
//             sx={{
//               position: "absolute",
//               top: "20px",
//               left: "10px",
//               color: "white",
//               padding: "10px 20px",
//               fontSize: "16px",
//               borderRadius: "5px",
//             }}
//           >
//             Start Recording
//           </Button>
//         ) : (
//           <Button
//             variant="contained"
//             color="warning"
//             onClick={stopRecording}
//             sx={{
//               position: "absolute",
//               top: "20px",
//               left: "20px",
//               color: "white",
//               fontSize: "16px",
//               borderRadius: "5px",
//             }}
//           >
//             Stop Recording
//           </Button>
//         )}

//         <Button
//           variant="contained"
//           color="success"
//           onClick={captureFrameAndDetectIMEI}
//           sx={{
//             width: "50%",
//             position: "absolute",
//             bottom: "20px",
//             left: "20px",
//             color: "white",
//             fontSize: "16px",
//             borderRadius: "5px",
//           }}
//         >
//           Capture Frame & Detect IMEI
//         </Button>
//       </Box>

//       {imei && <h3>Detected IMEI: {imei}</h3>}

//       {videoURL && (
//         <div>
//           <h2>Preview Recorded Video:</h2>
//           <video src={videoURL} controls width="100%" height="300px" />
//           <a href={videoURL} download="recorded-video.webm">
//             <button>Download Video</button>
//           </a>
//         </div>
//       )}
//     </Container>
//   );
// };

// export default ImeiDetectFromVideo;
