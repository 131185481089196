import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Box, Container } from "@mui/material";

const Routes = () => {
  const navigate = useNavigate();

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f3f4f6",
        px: { xs: 2, sm: 4, md: 6 },
      }}
    >
      <Box
        sx={{
          p: 2,
          gap: 2,
          display: "flex",
          flexDirection: "column",
          width: { xs: "90%", sm: "60%", md: "40%", lg: "30%" },
        }}
      >
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={() => navigate("/imei-detect")}
        >
          IMEI Detect From Video
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="warning"
          onClick={() => navigate("/screen-swap")}
        >
          Screen Swap Test
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="success"
          onClick={() => navigate("/xray-test")}
        >
          X-Ray Test
        </Button>
      </Box>
    </Container>
  );
};

export default Routes;
