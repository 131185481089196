// import React, { useState } from "react";

// const ScreenSwapDamageTest = () => {
//   const [touchPoints, setTouchPoints] = useState([]);

//   const handleTouchMove = (e) => {
//     const touches = Array.from(e.touches).map((touch) => ({
//       x: touch.clientX,
//       y: touch.clientY,
//     }));
//     setTouchPoints((prevPoints) => [...prevPoints, ...touches]);
//   };

//   const resetScreen = () => {
//     setTouchPoints([]); // Clear the colored points
//   };

//   return (
//     <div
//       style={{
//         position: "relative",
//         height: "100vh",
//         width: "100vw",
//         backgroundColor: "blue", // Initial screen color
//         overflow: "hidden",
//       }}
//       onTouchMove={handleTouchMove}
//     >
//       {/* Render colored dots for each touch point */}
//       {touchPoints.map((point, index) => (
//         <div
//           key={index}
//           style={{
//             position: "absolute",
//             top: point.y - 10, // Adjust to center the dot
//             left: point.x - 10,
//             width: "20px",
//             height: "20px",
//             borderRadius: "50%",
//             backgroundColor: "white", // Mark with white color
//             pointerEvents: "none", // Ensure no interference with touch events
//           }}
//         />
//       ))}

//       {/* Reset button */}
//       <button
//         onClick={resetScreen}
//         style={{
//           position: "absolute",
//           bottom: "20px",
//           right: "20px",
//           padding: "10px 20px",
//           fontSize: "16px",
//           backgroundColor: "red",
//           color: "white",
//           border: "none",
//           borderRadius: "5px",
//           cursor: "pointer",
//         }}
//       >
//         Reset
//       </button>
//     </div>
//   );
// };

// export default ScreenSwapDamageTest;

// *************** Updated Working Code 04 Feb ***************
// import React, { useState, useEffect } from "react";

// const ScreenSwapDamageTest = () => {
//   const [touchPoints, setTouchPoints] = useState([]);
//   const [damageDetected, setDamageDetected] = useState(false);
//   const [isTouching, setIsTouching] = useState(false);
//   const gridSize = 10; // Grid size to check touch coverage (adjust as necessary)
//   const [checkTriggered, setCheckTriggered] = useState(false); // State to track when the check is triggered

//   const handleTouchMove = (e) => {
//     const touches = Array.from(e.touches).map((touch) => ({
//       x: touch.clientX,
//       y: touch.clientY,
//     }));
//     setTouchPoints((prevPoints) => [...prevPoints, ...touches]);
//     setIsTouching(true);
//   };

//   const resetScreen = () => {
//     setTouchPoints([]); // Clear the touch points
//     setDamageDetected(false); // Reset damage detection
//     setCheckTriggered(false); // Reset the check trigger
//     setIsTouching(false); // Reset touch status
//   };

//   // Function to check if the screen is damaged
//   const checkScreenDamage = () => {
//     // Create a grid and check if any grid square is untouched
//     const grid = [];

//     touchPoints.forEach((point) => {
//       const xIndex = Math.floor(point.x / gridSize);
//       const yIndex = Math.floor(point.y / gridSize);
//       const key = `${xIndex}-${yIndex}`;
//       grid[key] = true;
//     });

//     // If we have areas in the grid that aren't touched, consider it "damaged"
//     const totalGridCells =
//       Math.floor(window.innerWidth / gridSize) *
//       Math.floor(window.innerHeight / gridSize);
//     const touchedCells = Object.keys(grid).length;
//     const untouchedCells = totalGridCells - touchedCells;

//     if (untouchedCells > totalGridCells * 0.1) {
//       // Threshold for detecting broken screen
//       setDamageDetected(true);
//       alert("Error: Screen not fully covered! Some areas are unresponsive.");
//     } else {
//       setDamageDetected(false);
//       alert("Success: Screen is fully covered!");
//     }
//   };

//   // Handle Check Screen button click
//   const handleCheckScreenClick = () => {
//     setCheckTriggered(true); // Mark that the check is triggered
//     checkScreenDamage(); // Check the screen coverage
//   };

//   useEffect(() => {
//     if (!isTouching) {
//       checkScreenDamage(); // Check for damage when touch stops
//     }
//   }, [touchPoints, isTouching]);

//   return (
//     <div
//       style={{
//         position: "relative",
//         height: "100vh",
//         width: "100vw",
//         backgroundColor: "blue", // Initial screen color
//         overflow: "hidden",
//       }}
//       onTouchMove={handleTouchMove}
//     >
//       {/* Render colored dots for each touch point */}
//       {touchPoints.map((point, index) => (
//         <div
//           key={index}
//           style={{
//             position: "absolute",
//             top: point.y - 10, // Adjust to center the dot
//             left: point.x - 10,
//             width: "20px",
//             height: "20px",
//             borderRadius: "50%",
//             backgroundColor: "white", // Mark with white color
//             pointerEvents: "none", // Ensure no interference with touch events
//           }}
//         />
//       ))}

//       {/* Damage Indicator */}
//       {damageDetected && (
//         <div
//           style={{
//             position: "absolute",
//             top: "20px",
//             left: "20px",
//             backgroundColor: "red",
//             color: "white",
//             padding: "10px",
//             borderRadius: "5px",
//           }}
//         >
//           Potential screen damage detected! Some areas may be unresponsive.
//         </div>
//       )}

//       {/* Check Screen button */}
//       <button
//         onClick={handleCheckScreenClick}
//         style={{
//           position: "absolute",
//           bottom: "20px",
//           right: "100px",
//           padding: "10px 20px",
//           fontSize: "16px",
//           backgroundColor: "green",
//           color: "white",
//           border: "none",
//           borderRadius: "5px",
//           cursor: "pointer",
//         }}
//       >
//         Check Screen
//       </button>

//       {/* Reset button */}
//       <button
//         onClick={resetScreen}
//         style={{
//           position: "absolute",
//           bottom: "20px",
//           right: "20px",
//           padding: "10px 20px",
//           fontSize: "16px",
//           backgroundColor: "red",
//           color: "white",
//           border: "none",
//           borderRadius: "5px",
//           cursor: "pointer",
//         }}
//       >
//         Reset
//       </button>
//     </div>
//   );
// };

// export default ScreenSwapDamageTest;
import React, { useState, useEffect } from "react";

const ScreenSwapDamageTest = () => {
  const [touchPoints, setTouchPoints] = useState([]);
  const [damageDetected, setDamageDetected] = useState(false);
  const [isTouching, setIsTouching] = useState(false);
  const gridSize = 10; // Grid size to check touch coverage (adjust as necessary)
  const [checkTriggered, setCheckTriggered] = useState(false); // State to track when the check is triggered

  const handleTouchMove = (e) => {
    const touches = Array.from(e.touches).map((touch) => ({
      x: touch.clientX,
      y: touch.clientY,
    }));
    setTouchPoints((prevPoints) => [...prevPoints, ...touches]);
    setIsTouching(true);
  };

  const resetScreen = () => {
    setTouchPoints([]); // Clear the touch points
    setDamageDetected(false); // Reset damage detection
    setCheckTriggered(false); // Reset the check trigger
    setIsTouching(false); // Reset touch status
  };

  // Function to check if the screen is damaged
  const checkScreenDamage = () => {
    // Create a grid and check if any grid square is untouched
    const grid = [];

    touchPoints.forEach((point) => {
      const xIndex = Math.floor(point.x / gridSize);
      const yIndex = Math.floor(point.y / gridSize);
      const key = `${xIndex}-${yIndex}`;
      grid[key] = true;
    });

    // If we have areas in the grid that aren't touched, consider it "damaged"
    const totalGridCells =
      Math.floor(window.innerWidth / gridSize) *
      Math.floor(window.innerHeight / gridSize);
    const touchedCells = Object.keys(grid).length;
    const untouchedCells = totalGridCells - touchedCells;

    if (untouchedCells > totalGridCells * 0.1) {
      // Threshold for detecting broken screen
      setDamageDetected(true);
      alert("Error: Screen not fully covered! Some areas are unresponsive.");
    } else {
      setDamageDetected(false);
      alert("Success: Screen is fully covered!");
    }
  };

  // Handle Check Screen button click
  const handleCheckScreenClick = () => {
    setCheckTriggered(true); // Mark that the check is triggered
    checkScreenDamage(); // Check the screen coverage
  };

  useEffect(() => {
    if (!isTouching) {
      checkScreenDamage(); // Check for damage when touch stops
    }
  }, [touchPoints, isTouching]);

  return (
    <div
      style={{
        position: "relative",
        height: "100vh",
        width: "100vw",
        backgroundColor: "blue", // Initial screen color
        overflow: "hidden",
        display: "flex",
        flexDirection: "column", // Ensure buttons are vertically aligned
        justifyContent: "space-between", // Space out buttons and content
        alignItems: "center", // Center the content
      }}
      onTouchMove={handleTouchMove}
    >
      {/* Render colored dots for each touch point */}
      {touchPoints.map((point, index) => (
        <div
          key={index}
          style={{
            position: "absolute",
            top: point.y - 10, // Adjust to center the dot
            left: point.x - 10,
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            backgroundColor: "white", // Mark with white color
            pointerEvents: "none", // Ensure no interference with touch events
          }}
        />
      ))}

      {/* Damage Indicator */}
      {/* {damageDetected && (
        <div
          style={{
            position: "absolute",
            top: "20px",
            left: "20px",
            backgroundColor: "red",
            color: "white",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          Potential screen damage detected! Some areas may be unresponsive.
        </div>
      )} */}

      {/* Check Screen button */}
      <button
        onClick={handleCheckScreenClick}
        style={{
          position: "absolute",
          top: "20px",
          left: "20px",
          backgroundColor: "green",
          color: "white",
          padding: "10px 20px",
          fontSize: "16px",
          borderRadius: "5px",
        }}
      >
        Check Screen
      </button>

      {/* Reset button */}
      <button
        onClick={resetScreen}
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          backgroundColor: "red",
          color: "white",
          borderRadius: "5px",
          padding: "10px 20px",
          fontSize: "16px",
        }}
      >
        Reset
      </button>
    </div>
  );
};

// Style for the button
const buttonStyle = {
  padding: "10px 20px",
  fontSize: "16px",
  backgroundColor: "green",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  margin: "10px",

  // position: "absolute",
  // top: "20px",
  // left: "20px",
  // color: "white",
  // padding: "10px 20px",
  // borderRadius: "5px",
  // backgroundColor: "green",
};

export default ScreenSwapDamageTest;
